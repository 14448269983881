.carousel .slick-arrow.slick-prev,
.carousel .slick-arrow.slick-next {
  background: transparent;
  width: 47px;
  height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  box-shadow: 1px 2px 10px -1px rgb(0 0 0 / 30%);
}
.carousel .slick-prev {
  left: 0;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.carousel .slick-next {
  right: 0;
  border-bottom-left-radius: 7px;
  border-top-left-radius: 7px;
}
.slick-prev::before,
.slick-next::before {
  display: none;
}
.custom-indicator {
  bottom: -65px;
}
.custom-indicator li {
  width: 50px;
  height: 50px;
  filter: grayscale(100%);
}
.custom-indicator li.slick-active {
  filter: grayscale(0%);
}
.multi__image:hover {
  transform: scale(1.1);
  transition: 500ms;
}